<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#"
                        v-if="$store.getters.can('tif.liquidaciones.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('tif.liquidaciones.create')"
                        @click="direccionar()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-1">
                          <label for="fecha">#</label>
                          <input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="filtros.id"
                            @input="v()"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label> Fecha Inicio</label>
                          <input
                            type="date"
                            class="form-control form-control-sm p-0"
                            v-model="filtros.fecha_inicio"
                            @input="validaFechas()"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label> Fecha Fin </label>
                          <input
                            type="date"
                            class="form-control form-control-sm p-0"
                            v-model="filtros.fecha_fin"
                            @input="validaFechas()"
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label> Empresa</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="empresa"
                            placeholder="Empresas"
                            label="razon_social"
                            class="form-control form-control-sm p-0"
                            :options="listasForms.empresas"
                            @search="buscarEmpresas"
                            @input="getIndex()"
                          ></v-select>
                        </div>
                        <div class="form-group col-md-2">
                          <label>Bloque</label>
                          <v-select
                            v-model="slct_bloque"
                            class="form-control form-control-sm p-0"
                            placeholder="Nombre"
                            label="nombre"
                            multiple
                            :options="listasForms.bloques"
                            @input="selectBloque()"
                          ></v-select>
                        </div>

                        <div class="form-group col-md-2">
                          <label>Operación</label>
                          <v-select
                            v-model="slct_operacion"
                            class="form-control form-control-sm p-0"
                            placeholder="Nombre"
                            label="descripcion"
                            multiple
                            :options="listasForms.operaciones"
                            @input="selectOperacion()"
                          ></v-select>
                        </div>

                        <div class="form-group col-md-3">
                          <label>Producto</label>
                          <v-select
                            v-model="slct_producto"
                            class="form-control form-control-sm p-0"
                            placeholder="Nombre"
                            label="nombre"
                            multiple
                            :options="listasForms.productos"
                            @input="selectProducto()"
                          ></v-select>
                        </div>

                      
                        <div class="form-group col-md-2">
                          <label> Tipo Tarifa</label>
                          <select
                            class="form-control form-control-sm"
                            v-model="filtros.tipo_tarifa"
                            @change="getIndex()"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="tarifa in listasForms.tipo_tarifas"
                              :key="tarifa.numeracion"
                              :value="tarifa.numeracion"
                            >
                              {{ tarifa.descripcion }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label> Firmas</label>
                          <select
                            v-model="filtros.firmado"
                            class="form-control form-control-sm"
                            @change="getIndex()"
                          >
                            <option value="">Todos</option>
                            <option value="firmado">Firmado</option>
                            <option value="no_firmado">No Firmado</option>
                          </select>
                        </div>
                        <div class="form-group col-md-2">
                          <label> Codigo de Aceptación</label>
                          <input
                            type="number"
                            v-model="filtros.aceptacion"
                            placeholder="Codigo de Aceptación"
                            class="form-control form-control-sm"
                            @keyup.enter="getIndex()"
                          />
                        </div>
                        <div class="form-group col-md-2">
                          <label> Estado</label>
                          <select
                            v-model="filtros.estado"
                            class="form-control form-control-sm p-0"
                            placeholder="Estado"
                            label="descripcion"
                            @change="getIndex()"
                          >
                            <option value="">Seleccione...</option>
                            <option
                              v-for="estado in listasForms.estados"
                              :key="estado.numeracion"
                              :value="estado.numeracion"
                            >
                              {{ estado.descripcion }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <table
                      class="table table-bordered table-striped table-hover table-sm"
                      style="font-size: 90%"
                    >
                      <thead class="thead">
                        <tr>
                          <th>#</th>
                          <th>Fecha Inicio</th>
                          <th>Fecha fin</th>
                          <th>Empresa</th>
                          <th>Bloque</th>
                          <th>Operacion</th>
                          <th>Producto</th>
                          <th>Tipo tarifa</th>
                          <th>Codigo de aceptacion</th>
                          <th class="text-center" style="min-width: 100px;">
                            Total
                          </th>
                          <th>Volumen</th>
                          <th>Estado</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody
                        v-for="liquidacion in liquidaciones.data"
                        :key="liquidacion.id"
                      >
                        <tr>
                          <td
                            class="text-center"
                            style="font-size: 11px; width: 5%"
                          >
                            {{ liquidacion.id }}
                          </td>
                          <td style="font-size: 11px; width: 7%">
                            {{ liquidacion.fecha_ini }}
                          </td>
                          <td style="font-size: 11px; width: 7%">
                            {{ liquidacion.fecha_fin }}
                          </td>
                          <td>
                            {{ liquidacion.empresa.razon_social }}
                          </td>
                          <td>
                            {{ liquidacion.bloque.nombre }}
                          </td>
                          <td>
                            {{ liquidacion.tif_operacion.Noperacion }}
                          </td>
                          <td>
                            {{ liquidacion.producto.nombre }}
                          </td>
                          <td>{{ liquidacion.nTarifa }}</td>
                          <td
                            class="text-center"
                            v-if="
                              liquidacion.estado == 2 &&
                                liquidacion.cargos_liqui.length > 0
                            "
                          >
                            <span
                              class="badge badge-success p-2"
                              style="font-size: 12px"
                              v-if="liquidacion.aceptacion != null"
                            >
                              {{ liquidacion.aceptacion }}
                            </span>
                            <button
                              v-else-if="
                                $store.getters.can(
                                  'tif.liquidaciones.aceptacion'
                                ) && liquidacion.aceptacion == null
                              "
                              title="Agregar Codigo De Aceptación"
                              type="button"
                              class="btn bg-primary text-white"
                              data-toggle="modal"
                              data-target="#assignNumberModal"
                              @click="llenarModalAceptacion(liquidacion.id)"
                            >
                              <i class="fas fa-dollar-sign"></i>
                            </button>
                          </td>
                          <td v-else></td>
                          <td>
                            <b v-if="liquidacion.tipo_tarifa == 2">
                              {{
                                "$ " +
                                  parseFloat(
                                    liquidacion.total_fv
                                  ).toLocaleString("es-ES")
                              }}
                            </b>
                            <b v-if="liquidacion.tipo_tarifa == 3">
                              {{
                                "$ " +
                                  parseFloat(
                                    liquidacion.total_tiempos
                                  ).toLocaleString("es-ES")
                              }}
                            </b>

                            <!--   {{ liquidacion.total }} -->
                          </td>
                          <td>{{ liquidacion.volumen }}</td>
                          <td class="text-center">
                            <span
                              class="badge"
                              :class="
                                liquidacion.estado == 1
                                  ? 'badge-danger'
                                  : liquidacion.estado == 2
                                  ? 'badge-success'
                                  : 'badge-warning'
                              "
                            >
                              {{ liquidacion.nEstado }}
                            </span>
                          </td>
                          <td style="width: 50px">
                            <div class="btn-group float-left">
                              <button
                                type="button"
                                class="btn btn-sm"
                                :class="
                                  liquidacion.cargos_liqui.length ==
                                  liquidacion.tif_liquidacion_firmas.length
                                    ? 'alert-default-success'
                                    : 'alert-default-info'
                                "
                                v-if="
                                  liquidacion.estado == 2 &&
                                    liquidacion.cargos_liqui.length > 0 &&
                                    $store.getters.can(
                                      'tif.liquidaciones.print'
                                    )
                                "
                                title="Ver PDF"
                                @click="verPDF(liquidacion.id)"
                              >
                                <i class="fas fa-print"></i>
                              </button>
                              <button
                                data-toggle="modal"
                                data-target="#modal-form-cargos"
                                type="button"
                                class="btn alert-default-light btn-sm"
                                v-else-if="
                                  liquidacion.estado == 2 &&
                                    liquidacion.cargos_liqui.length == 0 &&
                                    $store.getters.can(
                                      'tif.liquidaciones.asignacionCargos'
                                    )
                                "
                                title="Asignacion Cargos"
                                @click="showFirmas(liquidacion)"
                              >
                                <i class="fas fa-users"></i>
                              </button>
                              <!-- Botón Firmas -->
                              <button
                                type="button"
                                class="btn btn-sm"
                                :class="
                                  liquidacion.cargos_liqui.length ==
                                  liquidacion.tif_liquidacion_firmas.length
                                    ? 'alert-default-success'
                                    : 'alert-default-dark'
                                "
                                data-toggle="modal"
                                data-target="#Modal_firmas"
                                title="Firmar Liquidacion"
                                @click="
                                  $refs.tifLiquidacionesFirmas.getDataFirmas(
                                    liquidacion
                                  )
                                "
                                v-if="
                                  liquidacion.estado == 2 &&
                                    liquidacion.cargos_liqui.length > 0
                                "
                              >
                                <i class="fas fa-signature"></i>
                              </button>
                              <!-- Btn Ticket -->
                              <!-- <button
                                type="button"
                                class="btn btn-sm bg-success"
                                v-show="
                                  liquidacion.estado == 2 &&
                                  liquidacion.producto_id == 52 &&
                                  liquidacion.cargos_liqui.length > 0 &&
                                  liquidacion.tif_liquidacion_firmas.length ==
                                    liquidacion.cargos_liqui.length
                                "
                                @click="printTicket(liquidacion)"
                              >
                                <i class="fas fa-ticket-alt"></i>
                              </button> -->
                              <button
                                type="button"
                                class="btn btn-sm alert-default-primary"
                                v-if="
                                  $store.getters.can('tif.liquidaciones.create')
                                "
                                title="Editar Liquidacion "
                                @click="direccionar(liquidacion)"
                              >
                                <i class="fas fa-edit"></i>
                              </button>
                              <button
                                type="button"
                                class="btn btn-sm alert-default-danger"
                                v-if="
                                  $store.getters.can(
                                    'tif.liquidaciones.delete'
                                  ) && liquidacion.estado == 1
                                "
                                title="Eliminar"
                                @click="destroy(liquidacion)"
                              >
                                <i class="fas fa-trash-alt"></i>
                              </button>
                              <button
                                type="button"
                                class="btn alert-default-warning btn-sm"
                                data-toggle="modal"
                                data-target="#modal-form-crear-plantilla"
                                v-if="
                                  $store.getters.can(
                                    'tif.liquidaciones.planilla'
                                  ) && liquidacion.estado == 2
                                "
                                title="Plantillas"
                                @click="llenar_modal(liquidacion)"
                              >
                                <i class="fas fa-paste"></i>
                              </button>
                              <button
                                type="button"
                                class="btn bg-gradient-olive btn-sm"
                                data-toggle="modal"
                                data-target="#modal_contables"
                                @click="llenarModalContable(liquidacion)"
                                v-if="
                                  liquidacion.estado == 2 &&
                                    liquidacion.cargos_liqui.length > 0
                                "
                              >
                                <i class="fas fa-file-alt"></i>
                              </button>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="liquidaciones.total">
                  <p>
                    Mostrando del <b>{{ liquidaciones.from }}</b> al
                    <b>{{ liquidaciones.to }}</b> de un total:
                    <b>{{ liquidaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="liquidaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                >
                </pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <!-- Componente Firmas -->
    <TifLiquidacionesFirmas
      ref="tifLiquidacionesFirmas"
    ></TifLiquidacionesFirmas>
    <!---- Modal Cargos ---->
    <div class="modal fade" id="modal-form-cargos">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignación Cargos</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModal"
              id="close-modal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div>
              <table
                class="table table-bordered table-striped table-hover table-sm"
              >
                <thead class="thead-dark">
                  <tr>
                    <th class="text-center">Orden</th>
                    <th class="text-center">Usuario</th>
                    <th class="text-center">Cargo</th>
                    <th class="text-center">Estado</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="cargo in cargos" :key="cargo.id">
                    <td class="text-center">
                      <span> {{ cargo.orden }}</span>
                    </td>
                    <td>
                      <span> {{ cargo.user.name }} </span>
                    </td>
                    <td>
                      <span> {{ cargo.nCargo }} </span>
                    </td>
                    <td class="text-center">
                      <span> {{ cargo.nEstado }} </span>
                    </td>
                    <td class="text-center">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          :id="'control' + cargo.id"
                          v-model="cargo.check"
                        />
                        <label
                          class="custom-control-label"
                          :for="'control' + cargo.id"
                        ></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-md bg-success"
              @click="saveCargo()"
            >
              <i class="fas fa-check"></i>&nbsp;
              <span><small>Guardar</small></span>
            </button>
            <button
              type="button"
              class="btn btn-md bg-secondary"
              data-dismiss="modal"
            >
              <i class="fas fa-ban"></i>&nbsp;
              <span><small>Cerrar</small></span>
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- Modal Código de Aceptación -->
    <div
      class="modal fade"
      id="assignNumberModal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      style="background-color: #00000082"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-s" role="dialog">
        <div class="modal-content" id="modalContent">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h5
              class="modal-title modal-title text-white"
              id="assignNumberModalLabel"
            >
              Asignar Codigo De Aceptación
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal2"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Aquí puedes agregar los campos y elementos necesarios para asignar el número -->
            <input
              type="number"
              class="form-control"
              placeholder="Codigo De Aceptación"
              v-model="formAceptacion.aceptacion"
            />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cerrar
            </button>
            <button
              type="button"
              class="btn btn-primary"
              style="cursor: pointer"
              @click="guardarCodigoAceptacion()"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <TifAsignacionContableCecoAfe
        ref="TifAsignacionContableCecoAfe"
      ></TifAsignacionContableCecoAfe>
    </div>
    <TifLiquidacionPlantilla ref="TifLiquidacionPlantilla" />
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Loading from "../../../../components/Loading";
import TifLiquidacionesFirmas from "./TifLiquidacionesFirmas";
import TifAsignacionContableCecoAfe from "./TifAsignacionContableCecoAfe";
import TifLiquidacionPlantilla from "./TifLiquidacionPlantilla.vue";
import vSelect from "vue-select";
import moment from "moment";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "TifLiquidacionesIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
    vSelect,
    TifLiquidacionesFirmas,
    TifLiquidacionPlantilla,
    TifAsignacionContableCecoAfe,
  },
  data() {
    return {
      user: null,
      id: null,
      fecha_radicacion: null,
      cargando: false,
      liquidaciones: {},
      liquidacionSelect: null,
      tipo_vehiculo: null,
      slct_bloque: null,
      slct_producto: null,
      slct_operacion: null,
      filtros: {},
      bloque: null,
      cargos: {},
      turnosLiqui: {},
      operacion: null,
      formAceptacion: {
        aceptacion: null,
        tif_liquidacion_id: null,
      },
      listasForms: {
        estados: [],
        tipo_tarifas: [],
        bloques: [],
        empresas: [],
        aceptacion: [],
        operaciones: [],
        productos: [],
      },
      empresa: [],
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations: {},
  /*   computed: {
    totalValores() {
      return this.liquidacion.det_liquidaciones.map(valor => valor.valor_total);
    }
  }, */

  methods: {
    async getUser() {
      axios.get("/api/user").then((response) => {
        this.user = response.data;
      });
    },

    async getIndex(page = 1) {
      this.filtros.empresa_id = "";
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
      this.cargando = true;
      await axios
        .get("/api/tif/liquidaciones/index?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.liquidaciones = response.data;
        });
    },

    selectBloque() {
      this.filtros.bloque_id;
      if (this.slct_bloque) {
        this.filtros.bloque_id = this.slct_bloque.map((e) => e.id);
      }
      this.getIndex();
    },
    selectProducto() {
      this.filtros.producto_id;
      if (this.slct_producto) {
        this.filtros.producto_id = this.slct_producto.map((p) => p.id);
      }
      this.getIndex();
    },
    selectOperacion() {
      this.filtros.operacion_id;
      if (this.slct_operacion) {
        this.filtros.operacion_id = this.slct_operacion.map(
          (o) => o.numeracion
        );
      }
      this.getIndex();
    },

    async limpiar() {
      this.filtros.id = "";
      this.filtros.estado = "";
      this.filtros.fecha_inicio = "";
      this.filtros.fecha_fin = "";
      this.filtros.bloque_id = "";
      this.filtros.operacion_id = "";
      this.filtros.tipo_tarifa = "";
      this.filtros.producto = "";
      this.filtros.aceptacion = "";
      this.filtros.firmado = "";
      this.selectBloque = null;
      this.selectProducto = null;
      this.selectOperacion = null;
      this.bloque = null;
      this.aceptacion = null;
      this.getIndex();
    },

    getTipoTarifa() {
      axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    getBloques() {
      axios.get("/api/admin/bloques/lista").then((response) => {
        this.listasForms.bloques = response.data;
      });
    },

    getOperaciones() {
      axios.get("/api/lista/120").then((response) => {
        this.listasForms.operaciones = response.data;
      });
    },
    getProductos() {
      axios
        .get("/api/admin/productos/lista", {
          params: { linea_negocio_id: 3 },
        })
        .then((response) => {
          this.listasForms.productos = response.data;
        });
    },

    direccionar(liquidacion = null) {
      let accion = "Crear";
      if (liquidacion) {
        accion = "Editar";
      }
      return this.$router.push({
        name: "/Tif/TifLiquidacionesForm",
        params: {
          accion,
          liquidacion,
        },
      });
    },

    llenar_modal(liqui) {
      this.$refs.TifLiquidacionPlantilla.llenarModal(liqui);
    },
    llenarModalContable(liqui) {
      this.$refs.TifAsignacionContableCecoAfe.getDatosModal(liqui);
    },

    llenarModalDetTurno(turno) {
      this.$refs.TifTurnoDet.llenar_modal_detTurno(turno);
    },

    llenarModalNovedades(turno) {
      this.$refs.TifNovedades.llenar_modal_novedades(turno);
    },

    getEstados() {
      axios.get("/api/lista/112").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(liquidacion) {
      this.$swal({
        title:
          "Esta seguro de eliminar la liquidación: " +
          liquidacion.fecha_ini +
          "/" +
          liquidacion.fecha_fin +
          " " +
          liquidacion.bloque.nombre +
          "?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tif/liquidaciones/" + liquidacion.id).then(() => {
            this.cargando = false;
            this.$swal({
              icon: "success",
              title: "Se eliminó la liquidación exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.getIndex();
          });
        }
      });
    },

    verPDF(liquidacion) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/tif/liquidaciones/pdf/" + liquidacion,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    /* async printTicket(ticket) {
      let flag = true;
      let fecha = true;
      let fechaRad = moment().format("YYYY-MM-DD hh:mm:ss");
      if (ticket.fecha_radicacion == null) {
        fecha = false;
        await this.$swal({
          title: "Está seguro de generar el Ticket?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Generar!",
        }).then((result) => {
          if (!result.value) {
            flag = false;
          }
        });
      }

      if (flag == true) {
        let datos = {
          ticket: ticket.id,
          fecha_radicacion: fechaRad,
          fecha: fecha,
        };

        await axios({
          method: "GET",
          url: "/api/tif/liquidaciones/ticket",
          params: datos,
        })
          .then((response) => {
            let data = response.data;
            this.cargando = false;
            this.getIndex();
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    }, */

    async showFirmas(liquidacion) {
      this.liquidacionSelect = liquidacion.id;
      this.tipo_vehiculo = liquidacion.det_liquidaciones[0].tipo_vehiculo_id;
      this.cargando = true;
      await axios
        .get(
          "/api/tif/liquidaciones/getCargos?tif_liquidacion_id=" +
            this.liquidacionSelect
        )
        .then((response) => {
          let cargos = response.data;
          for (let i = 0; i < cargos.length; i++) {
            cargos[i].check = true;
          }
          this.cargos = cargos;
          this.cargando = false;
        });
    },

    saveCargo() {
      this.$swal({
        title: "Está seguro de guardar estos cargos?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Terminar!",
      }).then((result) => {
        if (result.value) {
          this.cargando = true;
          let datos = {
            tif_liquidacion_id: this.liquidacionSelect,
            cargos: this.cargos,
          };
          axios({
            method: "POST",
            url: "/api/tif/liquidaciones/cargosLiqui",
            data: datos,
          })
            .then((response) => {
              this.cargando = false;
              this.$refs.closeModal.click();
              this.$swal({
                icon: "success",
                title: "Los cargos se guardarón exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
              this.getIndex();
              this.correoFirmas(this.liquidacionSelect);
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    async buscarEmpresas() {
      let me = this;
      me.listasForms.empresas = [];
      var url = "api/admin/empresas/lista?estado=1";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },
    validaFechas() {
      const fecha_menor = new Date(this.filtros.fecha_inicio);
      const fecha_mayor = new Date(this.filtros.fecha_fin);
     
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicio no puede ser mayor a la fecha fin...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }else {
        this.getIndex();
      }
    },

    limpiarModal() {
      this.aceptacion = null;
      this.formAceptacion = {
        aceptacion: null,
        tif_liquidacion_id: null,
      };
    },

    llenarModalAceptacion(liquidacion) {
      this.limpiarModal();
      this.formAceptacion.tif_liquidacion_id = liquidacion;
    },

    guardarCodigoAceptacion() {
      let params = this.formAceptacion;
      this.$swal({
        title: "Esta seguro de crear un codigo de aceptación?",
        text: "No podra revertir esta acción",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Realizar!!",
      }).then((result) => {
        if (result.value) {
          axios({
            method: "POST",
            url: "/api/tif/liquidaciones/codigoAceptacion",
            data: params,
          })
            .then((response) => {
              this.$refs.closeModal2.click();
              this.getIndex();
              this.$swal({
                icon: "success",
                title: "La petición se creo exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            })
            .catch((e) => {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },

    correoFirmas(tif_liquidacion_id) {
      this.cargando = true;
      axios
        .get("/api/tif/liquidaciones/enviarCorreoFirmas", {
          params: {
            tif_liquidacion_id: tif_liquidacion_id,
            accion: 1,
          },
        })
        .then((response) => {
          this.cargando = false;
          this.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getUser();
    await this.getEstados();
    await this.getTipoTarifa();
    await this.getBloques();
    await this.getOperaciones();
    await this.getProductos();
  },
};
</script>
<style>
.popover-header {
  background-color: #001f3f;
  color: white;
}
</style>
